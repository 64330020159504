jQuery(document).ready(function () {
    var $ = jQuery;

    function home() {
        $("#home-slider .slider").slick({
            slidesToShow: 1,
            autoplaySpeed: 3000,
            autoplay: true,
            items: 1,
            dots: true,
            arrows: true,
            prevArrow: $('#home-slider .slider-arrow-prev'),
            nextArrow: $('#home-slider .slider-arrow-next')
        });
    }

    function productPage() {
        $('.product-view .product-options select')
            .wrap('<div class="select"></div>');

        $('.product-view .product-options').on('DOMSubtreeModified', function () {
            console.log($(this).find('select + div'));
            $(this).find('select + div').each(function () {
                $(this).appendTo($(this).parent().parent());
            });
        });

        $('.product-images .thumb-link').click(function () {
            $('.product-images .thumb-link').removeClass('active');
            $(this).toggleClass('active');
        });

        $('.product-images .product-image-gallery').slick({
            slidesToShow: 1,
            prevArrow: $('.slider-arrow-prev'),
            nextArrow: $('.slider-arrow-next'),
            dots: false,
            infinite: false,
            asNavFor: $('.product-images .product-image-thumbs'),

        });

        $('.product-images .product-image-thumbs').slick({
            slidesToShow: 4,
            infinite: false,
            arrows: false,
            dots: false,
            asNavFor: $('.product-images .product-image-gallery'),
            focusOnSelect: true,

        });
    }

    function isMobile() {
        return $('#menu-toggle:visible').length;
    }

    function header() {

        var timer;
        var delay = 1000;

        $('#nav > ul > li').hover(function (event) {
            var currentElement = $(this);
            $('#nav ul > li').not(currentElement).removeClass('is-open');
            if (currentElement.has('ul').length){
                currentElement.addClass("is-open");
            }
            clearTimeout(timer);
        }, function() {
            timer = setTimeout(function(){
                $('#nav > ul > li').removeClass("is-open");
            }, delay);
        });

        $('#header .toggle').click(function (e) {
            if (isMobile()) {
                e.preventDefault();
                var target = $($(this).data("toggle"));
                $('.toggle-content:visible').not(target).slideUp(150);
                target.slideToggle(150);
            }
        });

        var searchInput = $('#header-search input');
        searchInput.on('change', function() {
            if ($(this).val().length > 0) {
                $(this).addClass('no-hide');
            } else {
                $(this).removeClass('no-hide');
            }
        }).change();

        $('#header-search button').click(function(e) {
            if (searchInput.val().length === 0) {
                searchInput.toggleClass('no-hide');
                e.preventDefault();
                return false;
            }
        });
    }

    header();
    home();
    productPage();


});
